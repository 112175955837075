<template>
    <div class="h-full overflow-auto flex flex-col">
        
        <div 
            v-if="wordpress"
            class="container mx-auto py-12 flex-grow flex ">
            
                <div 
                    v-if="showPromo"
                    class="mx-auto xl:w-2/4 text-center">
                    
                    <div class="relative">
                        <div>
                            <h1 
                                class="m-8" 
                                v-html="wordpress.content.rendered" 
                            />
                            
                            <div class="mb-12">
                                <iframe style="width: 100%; height: 394px;" src="https://www.youtube-nocookie.com/embed/28Kyj7uftyI?controls=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <!-- <a 
                                    class="link cursor-pointer"
                                    @click="showPromo = false; showIntro = true;">
                                    Mer informasjon om veilederen
                                </a> -->
                            </div>
                            
                            <a
                                v-if="!$store.state.form"
                                @click="showPromo = false; showRegister = true;"
                                class="button submit uppercase ">
                                Start veilederen
                            </a>
                            <div v-else>
                                <p class="max-w-sm mx-auto">
                                    Du er i øyeblikket knyttet opp til en økt i veilederen.
                                    Du kan gjerne fortsette på denne, eller du kan starte en ny. Økten som forlates kan man finne tilbake til med lenke i tidligere mottatt e-post.
                                </p>
                                
                                <br>
                                
                                <router-link 
                                    class="button submit uppercase w-32" 
                                    :to="{ name: 'veileder', query: { id: $store.state.id }}">
                                    Fortsett økt
                                </router-link>
                                
                                <a
                                    class="button link uppercase w-32 ml-4" 
                                    @click="newSession()">
                                    Starte ny økt
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div 
                    v-if="showRegister"
                    class="mx-auto w-2/4 text-center pt-24">
                    
                    <h1 
                        class="mb-8 px-4" 
                        v-html="'For å kunne lagre svar i veilederen ber vi dere om å lagre e-postadressene'" 
                    />
                    
                    
                    <form class="flex mb-12 text-left">
                        <div class="w-1/2 pr-4">
                            <label for="">Tillitsvalgt</label>
                            <input 
                                v-model="emails.tillitsvalgt" 
                                type="email" 
                                required 
                                placeholder="tillitsvalgt@bedrift.no" 
                                class="w-full" 
                            />
                        </div>
                        <div class="w-1/2 pl-4">
                            <label for="">Leder</label>
                            <input 
                                v-model="emails.leder" 
                                type="email" 
                                required 
                                placeholder="leder@bedrift.no" 
                                class="w-full" 
                            />
                        </div>
                    </form>
                    
                    <a
                        @click="showPromo = true; showRegister = false;"
                        class="button link uppercase mr-2 w-32 ">
                        Tilbake
                    </a>
                    
                    <a
                        @click="register()"
                        class="button submit uppercase ml-2 w-32 ">
                        Registrer
                    </a>
                    
                    <!-- <router-link 
                        class="button submit uppercase ml-2 w-32" 
                        :to="{ name: 'veileder'}">
                        Registrer
                    </router-link> -->
                </div>
                
                <div 
                    v-if="showIntro"
                    class="mx-auto w-2/4 text-center pt-8">
                    
                    <h2 
                        v-html="introContent.title.rendered" 
                        class="font-bold text-lo-pink mb-6 "
                    />
                    
                    <div v-html="introContent.content.rendered" />
                    
                    <div class="text-center my-12">
                        <a
                            @click="showPromo = true; showIntro = false;"
                            class="button link uppercase w-32 ">
                            Tilbake
                        </a>
                    </div>
                    
                </div>
                
        
        </div>
        
        <Footer />
    </div>
</template>

<script>
    export default {
        components: {
            'Footer': () => import('@/components/Footer.vue'),
        },
                
        data(){
            return {
                wordpress: null,
                introContent: null,
                showPromo: true,
                showRegister: false,
                showIntro: false,
                emails: {
                    tillitsvalgt: process.env.NODE_ENV === 'production' ? null : 'tillitsvalgt@ecode.no',
                    leder: process.env.NODE_ENV === 'production' ? null : 'leder@ecode.no',
                },
            }
        },
        methods: {
            newSession(){
                if (! confirm('Økten du forlater kan du finne tilbake til med lenke i tidligere mottatt e-post. Er du sikker på at du vil forlate denne økten?')) return; 
                this.$store.state.form = [];
                this.$store.state.id = null;
                
                this.showPromo = false; 
                this.showRegister = true;
            },
            
            async register(){
                try {
                    if ( 
                        !this.emails.tillitsvalgt || 
                        this.emails.tillitsvalgt == '' ){
                        
                        throw Error( 'Skriv inn din epostadresse for å gå videre' );
                    }
                    
                    if ( 
                        !this.emails.leder || 
                        this.emails.leder == '' ){
                        
                        throw Error( 'Skriv inn din epostadresse for å gå videre' );
                    }
                    
                    const data = {
                        task: 'createSession',
                        emails: this.emails,
                    };
                    
                    const response = await this.$axios.post( this.backendApiEndpoint , data);
                    // console.log(response.data);
                    
                    if (
                        response.data && 
                        response.data.id) {
                        
                        let id = response.data.id;
                        
                        this.$store.state.form = [];
                        this.$store.state.id = response.data.id;
                        
                        this.$router.push({ name: 'veileder', query: { id: id } })
                    }
                    else {
                        throw Error();
                    }
                }
                catch (error) {
                    if (error.message) {
                        alert(error.message);
                    }
                    else {
                        alert('Beklager, vi klarte ikke å lage en ny økt. Vær vennlig å ta kontakt med oss for hjelp.')
                    }
                    
                }
            }
        },
        
        beforeDestroy () {
            this.$events.off('logoClicked');
        },
        
        async mounted(){
            if ( !this.$store.state.pages ) {
                this.$store.state.pages = await this.fetchPages();
            }
            this.wordpress = this.$store.state.pages.find( p => p.id == 10);
            this.introContent = this.$store.state.pages.find( p => p.id == 383);
            
			this.$events.on('logoClicked',  () => {
                this.showPromo = true; 
                this.showIntro = false;
                this.showRegister = false;
			});
        }
    }
</script>

<style lang="scss">

</style>
